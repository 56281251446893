import React from "react";
import "./Slink.css";

export default function Slink({ children, type, onClick, className, href }) {
  return (
    <a className={className} href={href} target="_blank">
      {children}
      <div className="icon">↗</div>
    </a>
  );
}
