import React from "react";
import "./Footer.css";
import Slink from "./Slink";
import Clock from "./Clock";

export default function Footer() {
  return (
    <div className="footer" id="footer">
      <div className="title">CONTACT</div>
      <div className="footer-container">
        <div className="ellipse2"></div>
        <div className="contact">
          <h2>Feel free to contact me</h2>
        </div>
        <div className="time">
          <div className="desk-t">
            <h2 className="orange-text">BCN</h2>
            <Clock />
          </div>{" "}
          <div className="mobile-t">
            <Clock />
          </div>
        </div>
        <div className="s-links">
          <Slink
            className="btn-secondary"
            href="https://www.linkedin.com/in/gabypineda7/"
          >
            LinkedIn
          </Slink>
          <Slink className="btn-secondary" href="https://github.com/girlatomic">
            Github
          </Slink>
          <Slink
            className="btn-secondary"
            href="mailto: gabygabypineda@gmail.com"
          >
            Email
          </Slink>
        </div>
      </div>
      <div className="rights">
        <div className="subtitle">Built with React by Gaby Pineda</div>
        <div className="subtitle">Designed by Alex Sasov</div>
        <div className="subtitle">©2022. All right reserved</div>
      </div>
      <div className="rights-mobile">
        <div className="subtitle">©2022. All right reserved</div>
      </div>
    </div>
  );
}
