import { React, useEffect, useRef } from "react";
import Button from "./Button";
import "./Hero.css";
import lottie from "lottie-web";
import { Link } from "react-scroll";

export default function Hero() {
  const animationContainer = useRef(null);

  useEffect(() => {
    const anim = lottie.loadAnimation({
      container: animationContainer.current,
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: require("../assets/animation/hero-animation"),
    });
    // anim.setSpeed(1);
    return () => anim.destroy();
  }, []);

  return (
    <div className="hero" id="hero">
      <div className="animation-container" ref={animationContainer}></div>
      <div className="hero-container">
        <div className="title">HI THERE</div>
        <div className="title-container">
          <div className="row1">
            <h1>
              I’m Gaby, a <span className="orange-text">Full-Stack</span>
            </h1>
          </div>
          <div className="row2">
            <h1>
              <span className="orange-text">Developer </span>
              with a
            </h1>
          </div>
          <div className="row3">
            <h1>keen eye for design.</h1>
          </div>
        </div>
        <div className="sub-container">
          <p>
            Focused at the point where user, business and technical requirements
            meet and tend to become a perfect product.
          </p>

          <Link to="footer" className="btn-primary">
            Get in touch
          </Link>
        </div>
      </div>
    </div>
  );
}
