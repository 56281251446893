import React from "react";
import "./Skills.css";

export default function Skills() {
  let skillsTop = [
    "JavaScript",
    "React.js",
    "HTML5",
    "Swift UI",
    "Node.js",
    "CSS3",
    "MySQL",
    "Vue.js",
    "Express.js",
    "Git",
    "GSAP",
    "Adobe CC",
    "Jest",
    "Agile",
    "Xcode",
    "Responsive Design",
    "APIs",
    "CSS3",
  ];

  let skillsBottom = [
    "Sass",
    "Swift",
    "Figma",
    "Project Management",
    "Node.js",
    "Scrum",
    "Web Design",
    "JavaScript",
    "Bootstrap",
    "React.js",
    "APIs",
    "Strategy",
    "Vue.js",
    "CSS3",
    "Branding",
    "HTML5",
    "Git",
    "MySQL",
    "Express.js",
  ];

  return (
    <div className="skills">
      <div className="s-container">
        <div className="top-line">
          {skillsTop.map((skillsTop, index) => (
            <div className="skill-container">
              <h4 key={index}>{skillsTop}</h4>
            </div>
          ))}
        </div>
        <div className="bottom-line">
          {skillsBottom.map((skillsBottom, index) => (
            <div className="skill-container">
              <h4 key={index}>{skillsBottom}</h4>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
