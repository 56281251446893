import React from "react";
import "./Toggler.css";
import { useContext } from "react";
import { ThemeContext } from "../contexts/theme";

export default function Toggler({ label }) {
  const [theme, toggleTheme] = useContext(ThemeContext);

  return (
    <div className="switch-wrapper">
      <label className="switch" htmlFor={label}>
        <input type="checkbox" name={label} onChange={toggleTheme} id={label} />
        <span className="slider"></span>
      </label>
    </div>
  );
}
