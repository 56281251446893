import React from "react";
import "./Works.css";
import emanager from "../assets/images/emanager.png";
import ddlink from "../assets/images/ddlink.png";
import meme from "../assets/images/meme.png";
import ash from "../assets/images/ash.png";
import Slink from "./Slink";

export default function Works() {
  return (
    <div className="works" id="works">
      <div className="title">Works</div>
      <div className="works-container">
        <div className="works-left">
          <div className="wl-content">
            <div className="mob-img" alt="emanager app">
              <img src={emanager} />
            </div>
            <div className="works-header">
              <h3>e-Manager</h3>
              <div className="tech">
                <h5>Javascript</h5>
                <h5>React.js</h5>
                <h5>Node.js</h5>
                <h5>MySQL</h5>
                <h5>CSS3</h5>
              </div>
            </div>
            <div>
              <p>
                A web-based management solution that helps small businesses to
                keep their orders and clients organized in a simple and fast way
                using a single platform.
              </p>
            </div>
            <div className="buttons">
              <Slink
                className="btn-secondary"
                href="https://github.com/girlatomic/e-manager-app"
              >
                Github
              </Slink>
              <Slink
                className="btn-secondary"
                href="https://gp-emanager-app.herokuapp.com/"
              >
                Live
              </Slink>
            </div>
          </div>
          <div className="wl-container">
            <img src={emanager} />
          </div>
        </div>

        <div className="works-right">
          <div className="wimg-container">
            <img src={ddlink} alt="ddlink app" />
          </div>
          <div className="w-content">
            <div className="works-header">
              <h3>DDlink</h3>
              <div className="tech">
                <h5>Javascript</h5>
                <h5>React.js</h5>
                <h5>CSS3</h5>
                <h5>HTML5</h5>
                <h5>Node.js</h5>
              </div>
            </div>
            <div className="w-description">
              <p>
                Full-Stack Web App: A platform that allows freelance designers
                and developers to connect with each other based on projects of
                interest and skills. It makes the search for work interactive,
                social and without information overload.
              </p>
            </div>

            <div className="buttons">
              <Slink
                className="btn-secondary"
                href="https://github.com/girlatomic/ddlink-app"
              >
                Github
              </Slink>
              {/* <Slink className="btn-secondary">Live</Slink> */}
            </div>
          </div>
        </div>

        <div className="works-left">
          <div className="wl-content">
            <div className="mob-img">
              <img src={meme} alt="meme generator app" />
            </div>
            <div className="works-header">
              <h3>Meme Generator</h3>
              <div className="tech">
                <h5>Javascript</h5>
                <h5>React.js</h5>
                <h5>Node.js</h5>
                <h5>Bootstrap</h5>
              </div>
            </div>
            <p>
              An app to generate memes! Upload your favorite image, customize
              your texts and generate the perfect meme for every situation. Hit
              the download button and share it around.
            </p>
            <div className="buttons">
              <Slink
                className="btn-secondary"
                href="https://github.com/girlatomic/meme-generator"
              >
                Github
              </Slink>
              <Slink
                className="btn-secondary"
                href="https://gp-meme-generator.herokuapp.com/"
              >
                Live
              </Slink>
            </div>
          </div>
          <div className="wl-container">
            <img src={meme} />
          </div>
        </div>
        <div className="works-right">
          <div className="wimg-container">
            <img src={ash} alt="ash app" />
          </div>
          <div className="w-content">
            <div className="works-header">
              <h3>Ariadna Scordo Handmade</h3>
              <div className="tech">
                <h5>Javascript</h5>
                <h5>Vue.js</h5>
                <h5>Node.js</h5>
                <h5>MySQL</h5>
                <h5>Bootstrap</h5>
              </div>
            </div>
            <div className="w-description">
              <p>
                Full-Stack app for artisan jewellery maker Ariadna Scordo
                business to showcase her work and advertise pieces that are
                available to purchase.
              </p>
            </div>
            <div className="buttons">
              <Slink
                className="btn-secondary"
                href="https://github.com/girlatomic/mvp-ash"
              >
                Github
              </Slink>
              {/* <Slink className="btn-secondary">Live</Slink> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
