import React from "react";
import profile from "../assets/images/img_rectangle1.png";
import Button from "./Button";
import "./About.css";
import CV from "../assets/CV_GP_FSD.pdf";

export default function About() {
  return (
    <div className="about" id="about">
      <div className="title">about</div>
      <div className="about-container">
        <div className="img-container">
          <img src={profile} />
        </div>
        <div className="about-content">
          <div>
            <h3>
              I am passionate about bringing the{" "}
              <span className="orange-text">technical and visual aspects</span>{" "}
              of digital products to life.
            </h3>
          </div>

          <div className="description">
            <p>
              After 8+ years of living and loving my career as a project manager
              in advertising, I was ready for a new challenge, so I decided to
              learn how to code. Now, I connect design, project management and
              development to propel projects to their greatest potential.
            </p>
            <div>
              <a href={CV} download="GP_FSD_CV">
                <Button className="btn-primary">Download CV</Button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
