import "./App.css";
import { useContext } from "react";
import { ThemeContext } from "./contexts/theme";
import NavBar from "./components/Navbar";
import Hero from "./components/Hero";
import Skills from "./components/Skills";
import About from "./components/About";
import Works from "./components/Works";
import Footer from "./components/Footer";

function App() {
  const [{ theme }] = useContext(ThemeContext);

  return (
    <div
      className="app"
      style={{
        backgroundColor: theme.backgroundColor,
        color: theme.color,
      }}
    >
      <div className="app-container">
        <NavBar />
        <Hero />
        <Skills />
        <About />
        <Works />
        <Footer />
      </div>
    </div>
  );
}

export default App;
