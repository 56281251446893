import { React, useRef, useState } from "react";
import "./NavBar.css";
import Slink from "./Slink";
import { Link } from "react-scroll";
import Toggler from "./Toggler";
import { ReactComponent as Close } from "../assets/images/close.svg";
import { ReactComponent as Hamburger } from "../assets/images/hamburger.svg";

export default function NavBar() {
  // const navRef = useRef();

  // const showNavbar = () => {
  //   navRef.current.classList.toggle("resonsive_nav");
  // };

  const [toggle, setToggle] = useState(false);

  const handleToggle = () => {
    setToggle(!toggle);
    console.log(toggle);
  };

  return (
    <nav id="nav">
      <div className={toggle ? "nav-container expanded" : "nav-container"}>
        <div className="nav-items" id="menu">
          <div className="nav-left">
            <div className="mobile-toggler">
              <Toggler />
            </div>
            <div className="brand">
              <Link to="hero" className="button-text">
                Gabriela Pineda
              </Link>
            </div>
            <div className="toggle-icons" onClick={handleToggle}>
              {!toggle ? (
                <button className="btn-hamburger">
                  <Hamburger />
                </button>
              ) : (
                <button className="btn-close">
                  <Close />
                </button>
              )}
            </div>
          </div>
          <div className="nav-right">
            <Link to="about" className="button-text">
              About
            </Link>
            <Link to="works" className="button-text">
              Works
            </Link>
            <Link to="footer" className="button-text">
              Contact
            </Link>
          </div>
          <div className="social-links">
            <Slink
              className="btn-secondary"
              href="https://www.linkedin.com/in/gabypineda7/"
            >
              LinkedIn
            </Slink>
            <Slink
              className="btn-secondary"
              href="https://github.com/girlatomic"
            >
              Github
            </Slink>
            <Slink
              className="btn-secondary"
              href="mailto: gabygabypineda@gmail.com"
            >
              Email
            </Slink>
          </div>
        </div>
        <div className="desktop-toggler">
          <Toggler />
        </div>
      </div>
    </nav>
  );
}
